import React from 'react';
import { useParams, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { ID_GOOGLE_ANALYTICS } from '../assets/Constants';
import ReactGA from 'react-ga';
import MetaDecorator from '../components/MetaDatos/MetaDecorator';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Markdown from 'markdown-to-jsx';
import postlistEn from '../assets/posts/postsEn.json';
import postlistEs from '../assets/posts/postsEs.json';

function Post() {

    
    let { postId } = useParams();
    const { i18n } = useTranslation();
    const lang = i18n.language;
    
    let postlist;
    lang === 'en' 
    ? postlist = postlistEn 
    : postlist = postlistEs
    
    const fetchedPost = {}
    let postExists = false;
    
    postlist.forEach((post, i) => {
        if (postId === post.url) {
            fetchedPost.title = post.title ? post.title : "No title given"
            fetchedPost.metaTitle = post.metaTitle ? post.metaTitle : "No metaTitle given"
            fetchedPost.meta_description = post.meta_description ? post.meta_description : "No meta_description given"
            fetchedPost.date = post.date ? post.date : "No date given"
            fetchedPost.read = post.read ? post.read : "No read given"
            fetchedPost.url = post.url ? post.url : "No url given"
            fetchedPost.imglink = post.imglink ? post.imglink : "No imglink given"
            fetchedPost.category = post.category ? post.category : "No category given"
            fetchedPost.content = post.content ? post.content : "No content given"
            postExists = true
            console.log("Successful Post")
        }})
        
        if (!postExists) {
            <Redirect to={`${lang}/${postExists}`} />
            return console.log("ERROR 404")
        }
//Configuración de Google Analytics
        ReactGA.initialize ({ID_GOOGLE_ANALYTICS}); 
        ReactGA.pageview (`/blog/${fetchedPost.url}`);

  return (
    <> 
      <MetaDecorator 
        title={fetchedPost.metaTitle}
        description={fetchedPost.meta_description}
        type='article'
        url={fetchedPost.url} />
      <article className="post--area -bg-section">
          <figure className="post--img">
              <img className="img-fluid" src={require(`../img/posts/${fetchedPost.imglink}`)} alt={fetchedPost.title} title={fetchedPost.title} loading="lazy" />
          </figure>
          <div className="container">
              <div className="row">
                  <div className="col-lg-1 col-sm-12"></div>
                  <div className="col-lg-10 blog--sidebar-left">
                    <Breadcrumb 
                      sectionClass='mb-4'
                      url='/blog'
                      inicio='Dreamblog'
                      namePage={fetchedPost.title}/>

                      <div className="blog--single mb_50">
                          <div className="post--content">
                              <h1 className="f_p f--size-40 f_600 t_color mt-3">{fetchedPost.title}</h1>
                              <div className="entry--post-info mb-2 text-capitalize">
                                  <code>{fetchedPost.date}</code>
                                  <code>{fetchedPost.read}</code>
                                  <code>{fetchedPost.category}</code>                                                        
                              </div>
                              <div className="mt-4 f_p">  
                                  <Markdown children={fetchedPost.content} />
                              </div> 
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-1">
                  </div>
              </div>
          </div>
      </article>
    </>
  );
}


export default Post;