import React from "react";
import BannerHome from "../components/Home/BannerHome/BannerHome";
import StaffAugmentation from '../components/Home/StaffHome/StaffAugmentation';
import Testimonial from '../components/Home/Testimonial/Testimonial';
import OurClients from '../components/Home/OurClients/OurClients';
import ReactGA from 'react-ga';
import { ID_GOOGLE_ANALYTICS } from '../assets/Constants';
import { useTranslation } from 'react-i18next';
import MetaDecorator from '../components/MetaDatos/MetaDecorator';
import BannerGreatPlace from '../components/Home/BannerGreatPlace/BannerGreatPlace';
import Inview from '../assets/Inview'
import Planets from "../components/Home/Planets/Planets"

const SliderBlogHome = React.lazy(() => import('../components/Home/SliderBlogHome/SliderBlogHome'));


    // Configuración de Google Analytics
    ReactGA.initialize ({ID_GOOGLE_ANALYTICS}); 
    ReactGA.pageview ("/");

function Home() {

    const { t } = useTranslation('translation', { keyPrefix: 'meta_datos' });

  return (
    <>
      <MetaDecorator 
        title={t('meta_title_Home')}
        description={t('meta_description_Home')}
        url="" />
      <div className='bg_black '>
        <BannerHome />

        <Planets />
      </div>
      <StaffAugmentation />
      <BannerGreatPlace />
      <Testimonial />
      <Inview headers={t('meta_description_staff')}>
        <SliderBlogHome />
      </Inview>
      <OurClients />
    </>
  )
}

export default Home;