import React from 'react';
import Banner from '../../Banner/Banner';
import './bannerStories.css';


function BannerStories({ subtitle_banner, text_banner, btn_banner, title_banner}) {

    

  return (
    <Banner classBanner= "bg_dark banner-stories bg_dark line-break" typeScroll="dark" >
        <div className='mt-4 mb-5 w-100'>
            <h2 className="mt-4 mb-5 f--size-30 m_color f_600">
                {title_banner}
            </h2>
            <a href="/customer-stories#form-contact" className="btn--stories text-white">
                {btn_banner}
            </a>

            
        </div>
        <div className="col-lg-7 mt-4 seo--banner-content text-left">
            <div>
              ⭐⭐⭐⭐⭐
            </div>
            <h1 className="mt-4 f--size-30 m_color f_600">
                {subtitle_banner}
            </h1>
            <p className="mb-5 w_color f--size-20 pr_0">
                {text_banner}
            </p>
        </div>
        <div className="col-lg-5" >
            <figure className="banner--img" >
                <img src={require (`img/stories-customers/banner-stories-customers-DreamCode.png` )} width="450" alt="Customers Stories DreamCode Software" loading="lazy" />               
                <div className="light-effect"></div>

            </figure>
        </div>          
    </Banner>
  )
}

export default BannerStories;