import React, { useRef, useEffect } from 'react';
import { NavLink } from 'react-router-i18n';
import { Trans } from 'react-i18next';


function MenuLinks({toBlog, toCareers, toSpanish, toEnglish, children}) {

const menuRef = useRef(null);

useEffect(() => {
    const links = menuRef.current.querySelectorAll('a.link-menu');
    links.forEach(link => {
        link.addEventListener('click', handleNavLinkClick);
    });

    return () => {
        links.forEach(link => {
            link.removeEventListener('click', handleNavLinkClick);
        });
    };
}, []);

const handleNavLinkClick = () => {
    if (menuRef.current.classList.contains('show')) {
        menuRef.current.classList.remove('show');
    }

    const toggleButton = document.querySelector('.navbar-toggler');
    if (toggleButton && !toggleButton.classList.contains('collapsed')) {
        toggleButton.classList.add('collapsed');
        toggleButton.setAttribute('aria-expanded', 'false');
    }
};

  return (
    <div ref={menuRef} className="collapse navbar-collapse justify-content-end" id="MenuContent">
        <ul className="navbar-nav menu ml-auto mr-3">
            <li className="dropdown submenu nav-item">
                <a href="/staff-augmentation" className="dropdown-toggle nav-link" >
                    <Trans i18nKey="menu.solutions">Solutions</Trans>
                </a>
                <ul role="menu" className="-bg-section dropdown-menu">
                    <li className="nav-item">
                        <NavLink exact title="Technological Service" className="nav-link link-menu" to='/services'>
                            <Trans i18nKey="menu.services">Technological Services</Trans>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact title="Staff Augmentation" className="nav-link link-menu" to='/staff-augmentation'>
                            <Trans i18nKey="menu.staff">Staff Augmentation</Trans>
                        </NavLink>
                    </li>
                </ul>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link link-menu" activeclass="active" spy="true" offset={0} onClick={handleNavLinkClick} to={`/customer-stories`}>
                <Trans i18nKey="menu.customers">Projects</Trans>
                </NavLink>
            </li>
            <li className="nav-item ">
                <NavLink className="nav-link link-menu" activeclass="active" spy="true" offset={0} duration={500} to={`/about`}>
                    <Trans i18nKey="menu.about">about</Trans>
                </NavLink>
            </li>
        </ul>
        <ul className="navbar-nav flex-direction-row justify-content-center">
            <li className='dropdown nav-item'>
                <NavLink className="btn_get btn_hover link-menu" to={toBlog}>DreamBlog</NavLink>
            </li>
            <li className='dropdown nav-item'>
                <NavLink className="btn_get btn_hover link-menu" to={toCareers}>
                    <Trans i18nKey="menu.careers">careers</Trans>
                </NavLink>
            </li>
        </ul>
        <ul className='navbar-nav pl-4 lang-dark'>
            <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                        <i className="ti-world"></i>
                    </a>
                <div className="dropdown-menu -bg-section" aria-labelledby="navbarDropdownMenuLink">
                    <NavLink className="dropdown-item p_color" ignoreLocale to={toSpanish}>
                        <Trans i18nKey="menu.spanish">spanish</Trans>
                    </NavLink>
                    <NavLink className="dropdown-item p_color" ignoreLocale to={toEnglish}>
                        <Trans i18nKey="menu.english">english</Trans>
                    </NavLink>
                </div>
            </li>
            <li className="nav-item dropdown">
                {children}
            </li>
        </ul>         
        
    </div>
  )
}

export default MenuLinks;