import React from 'react'
import { useTranslation } from 'react-i18next';
import MetaDecorator from '../components/MetaDatos/MetaDecorator';
import ReactGA from 'react-ga';
import { ID_GOOGLE_ANALYTICS } from '../assets/Constants';
import BannerStories from 'components/StoriesCustomers/BannerStories/BannerStories';
import StoriesCustomers from 'components/StoriesCustomers/Customers/StoriesCustomers';
import FormContact from '../components/Forms/FormContact/FormContact';

    // Configuración de Google Analytics
    ReactGA.initialize ({ID_GOOGLE_ANALYTICS}); 
    ReactGA.pageview ("/stories");

function Stories() {

  const { t } = useTranslation();

  return (
    <>
      <MetaDecorator 
        title={t('meta_datos.meta_title_stories')}
        description={t('meta_datos.meta_description_stories')}
        type='Website'
        url="devops" />
      <BannerStories 
        title_banner={t('stories.title_banner')}
        btn_banner={t('stories.btn_banner')}
        subtitle_banner={t('stories.subtitle_banner')}
        text_banner={t('stories.text_banner')} />
      <StoriesCustomers />
      
      <FormContact typeForm="bg_dark dark py_50 " />


    </>
  )
}

export default Stories;