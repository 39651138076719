import React from "react";
import { useTranslation } from 'react-i18next';
import ParallaxTitle from '../../Titles/ParallaxTitle';
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import './storiesCustomers.css'

export default function Stories() {

    const {t} = useTranslation();
    const section = t('stories.customers_stories', { returnObjects: true })

  return (
    <section className="sec_pad stories-customers bg_dark line-break">
      <div className="container">
        <ParallaxTitle 
            Title={t('stories.subtitle_stories')}
            classDiv="text-center pb-3"
            h2Class="text-center m_color f--size-30 f_600 mb-4" />

        { 
          section.map((section, index) => (
            <ContentSection
              key={index}
              title={section.title}
              subtitle={section.subtitle}
              text={section.text}
              image={section.image}
              isSlider={section.isSlider}
              paragraph={section.paragraph || []}
            />
          ))
        }
      </div>
      <div id="form-contact">
        <ParallaxTitle 
            Title={t('stories.title_form_stories')}
            Paragraph={t('stories.subtitle_form_stories')}
            classDiv="text-center pb-3"
            Pclass="f--size-20"
            h2Class="text-center m_color f--size-30 f_600 mb-4" />
      </div>
    </section>
  );
}

function ContentSection ({ title, subtitle, text, image, isSlider, paragraph} ) {

  return (

        <div className="item-storie row align-items-center line-break">
          <div className="col-lg-6">
            <h2 className="m_color">{title}</h2>
            <h3><strong>{subtitle}</strong></h3>
            {isSlider ? (
              <Swiper            
                loop={true}
                pagination={{ clickable: true, }}
                modules={[Pagination]}
                className="text-left"
              >
              {
                paragraph.map((paragraphs, index) => (
                <SwiperSlide key={index}>
                  <h3>{paragraphs.subtitle}</h3>
                  <p>{paragraphs.text}</p>
                </SwiperSlide>
              ))}             
              </Swiper> 
              ) : (
              <>
                <p>{text}</p>
              </>
            )}
          </div>
          <div className="col-lg-6 text-center logo-container ">
            <img src={require(`img/stories-customers/${image}`)} title={`${title} proyectos de DreamCode Software latinoamerica`} alt={`${title}:  DreamCode Software's Customers `} width="350" height="100%" loading="lazy"/>
            <div className="light-effect"></div>
          </div>
        </div>

  )
}
